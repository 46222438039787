// import react
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
// import mui components
import { Box, CircularProgress, ThemeProvider } from "@mui/material";

// import local components
import { getAnalytics } from "firebase/analytics";
import Navigation from "./components/navigation/Navigation";
import theme from "./components/UI/theme";

// import pages using React.lazy
const Home = React.lazy(() => import("./pages/Home"));
const FormPage = React.lazy(() => import("./pages/FormPage"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));
const FAQPage = React.lazy(() => import("./pages/FAQPage"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const ConfirmPage = React.lazy(() => import("./pages/ConfirmPage"));
const Footer = React.lazy(() => import("./components/UI/Footer"));

function App() {
	const analytics = getAnalytics();
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<ThemeProvider theme={theme}>
					<Navigation />
					<Suspense
						fallback={
							<Box sx={{ m: "auto" }}>
								<CircularProgress />
							</Box>
						}
					>
						<main>
							<Switch>
								<Route path="/" exact component={Home} />
								<Route path="/online-evaluation" component={FormPage} />
								<Route path="/appointment" component={ContactPage} />
								<Route path="/faq" component={FAQPage} />
								<Route path="/thankyou" component={ConfirmPage} />
								<Route path="*">
									<NotFound />
								</Route>
							</Switch>
						</main>
						<Footer />
					</Suspense>
				</ThemeProvider>
			</LocalizationProvider>
		</>
	);
}

export default App;
