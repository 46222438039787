import {
	AppBar,
	Typography,
	Hidden,
	Container,
	Stack,
	Link,
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';

import NavLinks from './NavLinks';
import NavMenu from './NavMenu';
const links = [
	{ title: 'Home', url: '/' },
	{ title: 'Online Evaluation', url: '/online-evaluation' },
	{ title: 'Appointment', url: '/appointment' },
	{ title: 'FAQ', url: '/faq' },
];

const Navigation = () => {
	return (
		<AppBar
			sx={{
				boxShadow: 'rgb(140 152 164 / 25%) 0px 3px 6px 0px',
				background: 'white',
				width: '100%',
			}}
			position='sticky'
			elevation={0}
		>
			<Container maxWidth='lg'>
				<Stack
					direction='row'
					justifyContent='space-between'
					alignItems='center'
					py={2}
				>
					<Link
						component={RouterLink}
						underline='hover'
						to='/'
						variant='body1'
						activeStyle={{
							textDecoration: 'none',
						}}
					>
						<Typography variant='h5' color='textPrimary'>
							cannabismd
						</Typography>
					</Link>
					<Stack direction='row' spacing={3}>
						<Hidden mdDown>
							<NavLinks links={links} />
						</Hidden>
						<Hidden mdUp>
							<NavMenu links={links} />
						</Hidden>
					</Stack>
				</Stack>
			</Container>
		</AppBar>
	);
};

export default Navigation;
